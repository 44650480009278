// Body
//
// Settings for the `<body>` element.

$body-bg:                  #ededed;
$body-color:              #212529;;

$font-family-sans-serif:  OpenSans,Arial,sans-serif;

 $enable-responsive-font-sizes : true;



// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 600px,
  md: 920px,
  lg: 1150px,
  xl: 1500px
) !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1253px
) !default;



// include all Bootstrap
@import "../node_modules/bootstrap/scss/bootstrap";

// owerride
input, button, select, optgroup, textarea {
	font-size: small;
	}


body {
  /* Margin bottom by footer height */
 /* margin-bottom: 60px; */
   min-height: 100vh;
}

.my_top_placment {
  padding: 85px 15px 0;
}

/*
@include media-breakpoint-up(sm) {
	  .my_top_placment {
  	 padding: 80px 15px 0;
	}
} */

 /*
 Footer 
-------------------------------------------*/
.footer {
  /*position: absolute;
  bottom: 0; */
  width: 100%;
  font-size: 10px;
  /* Set the fixed height of the footer here */
  height: 60px; 
  line-height: 18px; /* Vertically center the text there */
  /* background-color: #f5f5f5; */
}


.footer > .container {
  padding-right: 15px;
  padding-left: 15px;
}

.footer i {
	padding-top : 10px;
	font-size: 20px;
}

 /*
 Nav bar 
-------------------------------------------*/


.navbar {
	padding-left: 0px;
	padding-right: 0px;
	 box-shadow: 0px 0px 3px 0px #888888;
	z-index: 100;
	}
	
.navbar address{
	font-size: 12px;
	margin-bottom :0;
}

// ajoute padding pour en dessous du breakpoint suite à la supression plus haut	dans .navbar
// Generate series of `.navbar-expand-*` responsive classes for configuring
// where your navbar collapses.
.navbar-expand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        > .container,
        > .container-fluid {
          padding-right: 15px;
          padding-left: 15px;
        }
      }
    }
  }
}

 /*
 Contact Page 
-------------------------------------------*/

.contacts__map {
	display: block;
	width: 75vw;
	height: 300px;
	margin-top: 30px;
}

/*
 Stock Page 
-------------------------------------------*/

.search_bar{
	top : 76px; 
	z-index: 90;	
}

.search_bar > .container{
	padding-right: 5px;
    padding-left: 5px;
    background-color: #ddd;
	min-height: 38px;
	
}

.my_nav_bar_last_cell{
		padding-top: 0.25rem !important;
	}


@media (max-width: 1250px) { 
	
	.my_nav_bar_last_cell{
		justify-content: flex-start!important;
		padding-top: 0;
		margin-left:15px;
		padding-bottom:4px;
	}
	.quicksearch {
		margin-left:351px;
	}
 }

@include media-breakpoint-down(lg) {
	 #articleContener .article_wrap .article {	
		/* margin-left: 1rem;
		margin-right: 1rem;  */
	}
	
	.my_top_placment {
  		padding-top :121px;
	}
} 

@include media-breakpoint-down(md) {
	 .search_bar{
		top : -79px;
		
	}
	
	.my_top_placment {
  		padding-top :72px;
	}
}

@include media-breakpoint-down(xs) {
	
	.my_top_placment {
  		padding-top :53px;
	}
	
}


@include media-breakpoint-up(xl) {
		 #articleContener .article_wrap:nth-child(2n) .article {	
		margin-left: 1rem; 
		}
} 


